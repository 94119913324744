/* .description {
    margin: 2em 1em;
    padding: 2em;
    border: 1px solid #4a7f0f;
    font-size: 1.6em;
    font-family: "Montserrat";
    font-weight: 300;
    line-height: 1.5;
    box-shadow: 0px 4px 35px -8px #07401861;
    color: #1d330d;
} */

.description-enter {
  opacity: 0;
  height: 60%;
  /* transform: scaleY(0); */
}

.description-enter-active {
  opacity: 1;
  height: 100%;
  /* transform: scaleY(1); */
  /* transition: opacity 1000ms, transform 600ms; */
  transition: opacity 1000ms 500ms, height 1000ms;
}

.description-exit {
  opacity: 1;
  height: 100%;
  /* transform: scaleY(1); */
}

.description-exit-active {
  opacity: 0;
  height: 70%;
  /* transform: scaleY(0); */
  /* transition: opacity 400ms, transform 600ms; */
  transition: opacity 300ms, height 700ms;
}