/* .details-grid-enter {
  opacity: 0;
  width: 0%;
}

.details-grid-enter-active {
  opacity: 1;
  width: 100%;
  transition: opacity 1000ms, width 1000ms 50ms;
}

.details-grid-exit {
  opacity: 1;
  width: 100%;
}

.details-grid-exit-active {
  opacity: 0;
  width: 0%;
  transition: opacity 300ms, width 300ms;
} */

.details-grid-enter {
  opacity: 0;
  transform: translateX(100vw);
}

.details-grid-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1000ms, transform 1000ms;
}

.details-grid-exit {
  opacity: 1;
  transform: translateX(0);
}

.details-grid-exit-active {
  opacity: 0;
  transform: translateX(100vw);
  transition: opacity 300ms, transform 300ms;
}