@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Montserrat";
}

body, #root {
  margin: 0;
  padding: 0;
}

#root {
  /* display: flex; */
  flex-direction: column;
  /* height: 100vh; */
  justify-content: space-between;
}


.scroll {
  background: white;
  align-self: center;
  position: relative;
  /* width: 100%; */
  /* margin-top: 13vh; */
  margin-top: 20vh;
}
.scroll::before {
  animation: bounce 1s ease infinite;
  bottom: 2rem;
  color: #0F0F0F;
  content: '╲╱';
  font-size: 2rem;
  height: 4rem;
  left: 50%;
  letter-spacing: -1px;
  line-height: 4rem;
  margin-left: -3rem;
  opacity: 0.8;
  text-align: center;
  width: 6rem;
}
@keyframes bounce {
  50% {
      transform: translateY(-50%);
  }
}
.scroll {
  animation: move 1s infinite alternate;
}
@keyframes move {
  0% {
      transform: translateY(0px);
  }
  100% {
      transform: translateY(20px);
  }
}

.container {
  position: relative;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 100ms, transform 100ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 100ms, transform 100ms;
}


.slider__frame {
  transform: scale(0.8);
  transition: transform 150ms ease-in;
}

.glide__slide--active {
  transform: scale(1);
  transition: transform 150ms ease-in-out;
}