@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap);
/* Example demonstrating how to use `react-transition-group` and CSS to animate 
slides in `react-glidejs`. */

.fade-enter-active {
  opacity: .8;
  transition: opacity 250ms ease-in;
}

.fade-enter-done {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  /* box-shadow: inset 0 0 0 2px hsla(0, 0%, 100%, .5) !important; */
  background: transparent !important;
}
.mobile-description-enter {
  opacity: 0;
  height: 100%;
}

.mobile-description-enter-active {
  opacity: 1;
  height: 100%;
  transition: opacity 1000ms;
}

.mobile-description-exit {
  opacity: 1;
  height: 100%;
}

.mobile-description-exit-active {
  opacity: 0;
  height: 100%;
  transition: opacity 400ms;
}
/* .description {
    margin: 2em 1em;
    padding: 2em;
    border: 1px solid #4a7f0f;
    font-size: 1.6em;
    font-family: "Montserrat";
    font-weight: 300;
    line-height: 1.5;
    box-shadow: 0px 4px 35px -8px #07401861;
    color: #1d330d;
} */

.description-enter {
  opacity: 0;
  height: 60%;
  /* transform: scaleY(0); */
}

.description-enter-active {
  opacity: 1;
  height: 100%;
  /* transform: scaleY(1); */
  /* transition: opacity 1000ms, transform 600ms; */
  transition: opacity 1000ms 500ms, height 1000ms;
}

.description-exit {
  opacity: 1;
  height: 100%;
  /* transform: scaleY(1); */
}

.description-exit-active {
  opacity: 0;
  height: 70%;
  /* transform: scaleY(0); */
  /* transition: opacity 400ms, transform 600ms; */
  transition: opacity 300ms, height 700ms;
}
/* .details-grid-enter {
  opacity: 0;
  width: 0%;
}

.details-grid-enter-active {
  opacity: 1;
  width: 100%;
  transition: opacity 1000ms, width 1000ms 50ms;
}

.details-grid-exit {
  opacity: 1;
  width: 100%;
}

.details-grid-exit-active {
  opacity: 0;
  width: 0%;
  transition: opacity 300ms, width 300ms;
} */

.details-grid-enter {
  opacity: 0;
  transform: translateX(100vw);
}

.details-grid-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1000ms, transform 1000ms;
}

.details-grid-exit {
  opacity: 1;
  transform: translateX(0);
}

.details-grid-exit-active {
  opacity: 0;
  transform: translateX(100vw);
  transition: opacity 300ms, transform 300ms;
}
html {
  scroll-behavior: smooth;
}

* {
  font-family: "Montserrat";
}

body, #root {
  margin: 0;
  padding: 0;
}

#root {
  /* display: flex; */
  flex-direction: column;
  /* height: 100vh; */
  justify-content: space-between;
}


.scroll {
  background: white;
  align-self: center;
  position: relative;
  /* width: 100%; */
  /* margin-top: 13vh; */
  margin-top: 20vh;
}
.scroll::before {
  -webkit-animation: bounce 1s ease infinite;
          animation: bounce 1s ease infinite;
  bottom: 2rem;
  color: #0F0F0F;
  content: '╲╱';
  font-size: 2rem;
  height: 4rem;
  left: 50%;
  letter-spacing: -1px;
  line-height: 4rem;
  margin-left: -3rem;
  opacity: 0.8;
  text-align: center;
  width: 6rem;
}
@-webkit-keyframes bounce {
  50% {
      transform: translateY(-50%);
  }
}
@keyframes bounce {
  50% {
      transform: translateY(-50%);
  }
}
.scroll {
  -webkit-animation: move 1s infinite alternate;
          animation: move 1s infinite alternate;
}
@-webkit-keyframes move {
  0% {
      transform: translateY(0px);
  }
  100% {
      transform: translateY(20px);
  }
}
@keyframes move {
  0% {
      transform: translateY(0px);
  }
  100% {
      transform: translateY(20px);
  }
}

.container {
  position: relative;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 100ms, transform 100ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 100ms, transform 100ms;
}


.slider__frame {
  transform: scale(0.8);
  transition: transform 150ms ease-in;
}

.glide__slide--active {
  transform: scale(1);
  transition: transform 150ms ease-in-out;
}
